:root {
  --color-bg: #ffffff;
  --color-text: #000000;
  --color-black: #000000;
  --color-grey: #c8c8c8;
  --grey: #c8c8c8;
  --color-blue: #0075ff;
  --color-white: #ffffff;
  --color-table-border: #e8e8e8;
  --color-errorMessage: #ff2942;
}

html.dark {
  --color-bg: #111111;
  --color-text: #ffffff;
  --color-grey: #595959;
  --color-table-border: #383838;
}

html {
  font-size: 62.5%;
  background-color: var(--color-bg);
}

body {
  font-family: 'Unica 77 MonoLL', sans-serif;
  background-color: var(--color-bg);
  color: var(--color-text);
  font-size: 1rem;
  overflow-x: hidden;
  zoom: 1;
}

#root {
  -webkit-overflow-scrolling: touch;
}

main {
  margin: 0 1.2rem 1.2rem 1.2rem;
  padding-top: 16.4rem;
  min-height: calc(100dvh - 313px);
}

p::selection,
span::selection {
  color: var(--color-grey);
}

li a {
  color: var(--color-text);
}

/** Global */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

label {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.4rem;
  margin-left: 1px;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.dim {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100vh;
  width: 100%;
}

.checkbox {
  display: flex;
  width: 0.3rem;
  height: 0.3rem;
  background-color: var(--color-grey);
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  /*margin-bottom: 1.2rem;*/
}

.menu-items li {
  display: flex;
  color: var(--color-grey);
  opacity: 0;
  transition: opacity step-end;
}

.menu-items li.show {
  opacity: 1;
}

.menu-items li label:hover span {
  color: var(--color-text) !important;
}

.menu-items li label:hover .checkbox {
  background-color: var(--color-text) !important;
}

/** Header */
header {
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 2;
}

header .menu-items input {
  width: 0;
}

header .left-menu {
  width: 50%;
}

header .left-menu button {
  display: block;
  padding: 1.2rem;
  background-color: var(--color-bg);
}

header .left-menu button span {
  width: 100%;
  height: 100%;
}

header .right-menu {
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1.2rem;
  width: calc(50% + 1.2rem);
  padding: 1.2rem;
  background-color: var(--color-bg);
  z-index: 3;
}

header .right-menu > li:first-child {
  grid-column: 1;
  display: flex;
  width: 23.8rem;
  gap: 0.6rem;
}

header .right-menu > li:nth-child(2) {
  grid-column: 3;
  display: flex;
  width: 36.3rem;
  gap: 0.6rem;
}

header .right-menu > li:last-child {
  grid-column: 6;
  margin-left: auto;
}

header .header__navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding-left: 1.2rem;
  background-color: var(--color-bg);
  padding-bottom: 1.2rem;
}

header .header__navigation--title {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 1.2rem 1.2rem 0;
}

/** Main */
/** TEXT VIEW */
main .text-view {
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

main .text-view > div {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

main .product_list li {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1.2rem;
  padding: 0.6rem 0;
  cursor: pointer;
}

main .product_list li::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: transparent;
  transition: background-color 0.1s linear;
  position: absolute;
  bottom: 0;
}

main .product_list li span {
  opacity: 0;
  transition: opacity step-end;
}

main .product_list li span.show {
  opacity: 1;
}
main .product_list li.hover::after,
main .product_list li:hover::after {
  background-color: var(--color-table-border);
}

main .product_list li.product_item span:first-child {
  grid-column: 1;
}

main .product_list li.product_item span:nth-child(2) {
  grid-column: 2 / 6;
}

main .product_list li.product_item span:nth-child(3) {
  grid-column: 6;
}

main .product_list li.product_item span:nth-child(4) {
  grid-column: 7 / 11;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 12px);
}

main .product_list li.product_item span:nth-child(5) {
  grid-column: 11;
}

main .product_list li.product_item span:last-child {
  grid-column: 12 / 13;

}

main .product_list li.lookbook_item > span:first-child {
  grid-column: 1;
}

main .product_list li.lookbook_item > span:nth-child(2) {
  grid-column: 2;
}

main .product_list li.lookbook_item > span:last-child {
  grid-column: 6;
}


/** IMAGE VIEW */
.products {
  display: grid;
  gap: 1.2rem;
}

.products.filtered_products.hidden {
  display: none;
}

.products li {
  visibility: hidden;
  opacity: 0;
  aspect-ratio: 4/5;
  position: relative;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.products li.loaded {
  visibility: visible;
  opacity: 1;
}

.product-detail img {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.product-detail img.loaded {
  visibility: visible;
  opacity: 1;
}

.products li div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.products li div.hidden {
  display: none;
}

/** Hover Style Default */
.products li .hover-text {
  inset: 0;
  z-index: 1;
  position: absolute;
  display: none;
  align-items: flex-start;
  flex-direction: column;
  justify-content: end;
  padding: 2rem;
  line-height: 135%;
  background-color: var(--color-bg);
}

.products li:hover .hover-text {
  display: flex;
}

.sorted-products h2 {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.sorted-products h2.loaded {
  visibility: visible;
  opacity: 1;
}

/** Hover Style Grid 9 */
.grid_9 li .hover-text {
  padding: 2.4rem;
}

/** Hover Style Grid 9 and Open Drawer */
.gird_4 li .hover-text {
  padding: 3rem;
}

/** Hover Style Grid 6  */
.grid_6 li .hover-text {
  padding: 3.4rem;
}

.sorted-products {
  display: flex;
  flex-direction: column;
  gap: 12.4rem;
}

.sorted-products > div {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

/** Drawer */
#drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(50% + 0.6rem);
  height: 100%;
  padding: 1.2rem;
  background-color: var(--color-bg);
  z-index: 20;
  transition: right 0.3s linear;
  overflow-y: scroll;
}

.menu-items {
  position: relative;
}

.menu-items.info p {
  font-size: 1rem;
  line-height: 140%;
  padding-right: 1.2rem;
}

.info .invisible_text {
  color: var(--color-bg);
}

.info .info_text {
  position: absolute;
}

/** Product Drawer */
#drawer .product-detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#drawer .product-detail picture {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#drawer .lookbook-detail picture img,
#drawer .product-detail picture img {
  width: 100%;
}

#drawer .product-detail > div:first-child {
  display: flex;
  justify-content: space-between;
}

#drawer .product-detail > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 60%;
  aspect-ratio: 1 / 1;
  margin: 0 auto;
}

#drawer .product-detail > div:nth-child(2) > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
}

#drawer .product-detail > div:nth-child(2) .bullets {
  display: flex;
  justify-content: center;
  gap: 0.6rem;
}

#drawer .product-detail > div:nth-child(2) .bullets li {
  width: 0.5rem;
  height: 0.5rem;
}

#drawer .product_info {
  display: flex;
  flex-direction: column;
  line-height: 135%;
  cursor: default;
}

#drawer .product_info_row {
  display: flex;
  border-top: 1px solid var(--color-table-border);
  padding: 0.475rem 0;
}
#drawer .product_info_row span {
  width: 100%;
}

/** Lookbook Drawer */
#drawer .lookbook-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

#drawer .lookbook-detail > div:first-child {
  display: flex;
  justify-content: space-between;
}

#drawer .lookbook-detail > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 4 / 5;
  margin: 0 auto;
  width: 60%;
}

#drawer .lookbook-detail > div:nth-child(2).wideImage {
  width: 72%;
  aspect-ratio: 5 / 4;
}


#drawer .lookbook-detail picture img {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

#drawer .lookbook-detail picture img.loaded {
  visibility: visible;
  opacity: 1;
}

#drawer .lookbook-detail .lookbook_info {
  width: 100%;
}

#drawer .lookbook-detail .lookbook_info li {
  border-top: 1px solid var(--color-table-border);
  padding: 0.5rem 0rem;
  display: flex;
}

#drawer .lookbook-detail .lookbook_info li div {
  width: 50%;
}

#drawer .lookbook-detail .lookbook_info li div a {
  color: var(--color-blue);
}

/** Footer */
footer {
  margin: 1.2rem;
  padding-bottom: env(safe-area-inset-bottom);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

footer.loaded {
  opacity: 1;
  visibility: visible;
}

footer.drawer-open {
  width: calc(50% - 1.8rem);
}

footer > div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.2rem;
}

footer > div:first-child {
  padding: 9.6rem 0;
  align-items: center;
}

footer > div:first-child svg {
  height: 43px;
  width: fit-content;
}

footer > div.open-drawer-footer {
  display: flex;
}

footer .nav-footer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.2rem;
  justify-content: space-between;
  line-height: 145%;
  /*z-index: 13;*/
}

footer .nav-footer .year {
  grid-column: 3;
  justify-self: end;
}

footer .nav-footer ul li a {
  text-decoration: underline;
}

footer.drawer-open .nav-footer {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

/** Floating-bar */
.floating-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.floating-bar .floating-bar__menu {
  display: flex;
  gap: 0.4rem;
  padding: 0.4rem;
  width: fit-content;
  background-color: var(--color-bg);
}

#safe-area {
  height: env(safe-area-inset-bottom);
  background-color: var(--color-bg);
}

.floating-bar .floating-bar__menu button {
  padding: 0.8rem;

}

.floating-bar .floating-bar__menu .scale  {
  display: flex;
}

.floating-bar .floating-bar__menu .scale .range-input {
  z-index: 3;
}

/** 기존에 있던 design을 없애주는 역할 */
input[type='range'] {
  width: 12rem;
  -webkit-appearance: none;
  background: transparent;
  opacity: 1;
}

/** 사용자가 움직이는 포인터 역할 */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  margin-bottom: -1.5rem;
  background-color: transparent;
  box-shadow: 2px 2px 5px transparent;
}

/** 포인터가 움직이는 track 역할 */
input[type='range']::-webkit-slider-runnable-track {
  width: 12rem;
  height: 400%;
  cursor: pointer;
}

/** 포인터를 눌렀을때 track의 역할 */
input[type='range']:focus::-webkit-slider-runnable-track {
  /* background-color: var(--color-text); */
}

.range-input {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.range {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.range-input .thumb {
  position: absolute;
  left: 0;
  background-color: var(--color-text);
  cursor: pointer;
  width: 0.6rem;
  height: 0.6rem;
  transition: left 0.5s ease; /* 추가: left 속성에 대한 애니메이션 설정 */
  pointer-events: none;
  z-index: 999;
}

.range-input .line {
  position: absolute;
  left: 0;
  width: 12rem;
  height: 0.1rem;
  cursor: pointer;
  background-color: var(--color-text);
  pointer-events: none;
}

/** Loading */
.loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-bg);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading p {
  line-height: 100%;
  animation-name: loading-animation;
  animation-timing-function: step-end;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes loading-animation {
  100% {
    color: var(--color-text);
  }
  50% {
    color: transparent;
  }
  0% {
    color: var(--color-text);
  }
}

/** 반응형 */
@media screen and (min-width: 1601px) and (max-width: 1715px) {
  /** Hover style Default */
  .grid_12 li .hover-text span.product-hoverText-size {
    display: none;
  }

  #drawer .product-detail > div:nth-child(2) {
    /*width: 70%;*/
  }
}

@media screen and (min-width: 1377px) and (max-width: 1600px) {
  /* Hover style Default */
  .grid_12 li .hover-text span.products-hoverText-category,
  .grid_12 li .hover-text span.product-hoverText-size {
    display: none;
  }

  .grid_12 li .hover-text {
    padding: 1.4rem;
  }

  #drawer .product-detail > div:nth-child(2) {
    /*width: 60%;*/
  }
}

/* 태블릿 대형 스타일 */
@media screen and (min-width: 1025px) and (max-width: 1376px) {
  /** Main */

  main .product_list li.product_item,
  main .product_list li.lookbook_item {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  main .product_list li.product_item span:first-child {
    grid-column: 1;
  }

  main .product_list li.product_item span:nth-child(2) {
    grid-column: 2 / 4;
  }

  main .product_list li.product_item span:nth-child(3) {
    grid-column: 5;
  }

  main .product_list li.product_item span:nth-child(4) {
    grid-column: 6 / 9;
    width: 100%;
  }

  main .product_list li.product_item span:nth-child(5),
  main .product_list li.product_item span:last-child {
    display: none;
  }

  main .product_list li.lookbook_item > span:last-child {
    grid-column: 5;
  }

  /** Hover Style Grid 9 */
  .grid_9 li .hover-text {
    padding: 1.2rem;
  }

  .grid_9 li:hover .hover-text span.products-hoverText-category,
  .grid_9 li:hover .hover-text span.product-hoverText-size {
    display: none;
  }

  /** Hover Style Grid 9 and Open Drawer */
  .gird_4 li .hover-text {
    padding: 1.2rem;
  }

  /** Hover Style Grid 6  */
  .grid_6 li .hover-text {
    padding: 2.4rem;
  }

  /** Hover Style Grid 3 */
  .grid_3 li .hover-text {
    padding: 3.4rem;
  }

  .grid_2 li .hover-text {
    padding: 3rem;
  }

  .products li .hover-text span.product-hoverText-size {
    display: none;
  }

  /** Drawer */
  #drawer .product-detail > div:nth-child(2) {
    width: 80%;
  }

  #drawer .product_info,
  #drawer .lookbook_info {
    padding-bottom: 1rem;
  }

  #drawer .product-detail > div:nth-child(2) .bullets li {
    width: 0.4rem;
    height: 0.4rem;
  }

  footer div:first-child svg {
    height: 32px;
    width: fit-content;
  }
}

/* 태블릿 소형 스타일 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  main {
    min-height: calc(100dvh - 397px);
    padding-top: 8rem;
  }

  /** Header */
  header {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    gap: 0;
  }

  header .left-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  header .left-menu button {
    background-color: var(--color-bg);
  }

  header .right-menu {
    position: static;
    display: flex;
    flex-direction: column;
    /* background-color: var(--color-bg); */
    gap: 1.2rem;
    padding: 1.2rem;
    width: 100%;
  }

  header .right-menu > li:first-child {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    /* gap: 0.6rem */
    margin-bottom: 1.2rem;
  }

  header .right-menu > li:nth-child(2) {
    margin-bottom: 1.2rem;
  }

  header .right-menu > li:last-child {
    margin-left: 0;
  }

  header .header__navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding-left: 1.2rem;
  }

  .header__navigation .back-btn {
    display: flex;
    padding: 1.2rem 0;
    margin-bottom: 1.2rem;
    color: var(--color-text);
    text-decoration: underline;
  }

  /** Main */
  main .product_list li {
    grid-template-columns: repeat(8, 1fr);
  }

  main .product_list li.product_item span:nth-child(2) {
    grid-column: 2 / 5;
  }

  main .product_list li.product_item span:nth-child(3) {
    grid-column: 5;
  }

  main .product_list li.product_item span:nth-child(4) {
    grid-column: 6 / 9;
    width: 100%;
  }

  main .product_list li.product_item span:nth-child(5),
  main .product_list li.product_item span:last-child {
    display: none;
  }

  main .product_list li.lookbook_item span:nth-child(3) {
    grid-column: 5;
  }

  /** Hover */
  .grid_6 li .hover-text {
    padding: 2rem;
  }

  .grid_2 li .hover-text {
    padding: 6rem;
  }

  /** Drawer */
  #drawer {
    width: 100vw;
    height: 100dvh;
    position: absolute;
  }

  #drawer .product_info,
  #drawer .lookbook_info {
    padding-bottom: 1rem;
  }

  #drawer .product-detail > div:nth-child(2) {
    width: 70%;
  }

  #drawer .product-detail > div:nth-child(2) .bullets li {
    width: 0.4rem;
    height: 0.4rem;
  }

  footer div:first-child svg {
    height: 32px;
    width: fit-content;
  }

  footer .nav-footer {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 863px) {
  .grid_6 li .hover-text {
    padding: 1.4rem;
  }
  .grid_6 li .hover-text span.product-hoverText-size {
    display: none;
  }
}

/* 모바일 스타일 */
@media screen and (max-width: 767px) {
  /** Base */
  main {
    min-height: calc(100dvh - 380px);
    padding-top: 8rem;
  }

  /** Header */
  header {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    gap: 0;
    /* padding: 0; */
  }

  header .left-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  header .left-menu button {
    background-color: var(--color-bg);
  }

  header .right-menu {
    width: 100%;
    position: static;
    display: flex;
    flex-direction: column;
    /* background-color: var(--color-bg); */
    gap: 1.2rem;
    padding: 1.2rem;
  }

  header .right-menu > li:first-child {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    /* gap: 0.6rem */
    margin-bottom: 1.2rem;
  }

  header .right-menu > li:nth-child(2) {
    margin-bottom: 1.2rem;
  }

  header .right-menu > li:last-child {
    margin-left: 0;
  }

  .header__navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }

  .header__navigation .back-btn {
    display: flex;
    padding: 1.2rem 0;
    margin-bottom: 1.2rem;
    color: var(--color-text);
    text-decoration: underline;
  }

  .products {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  /** Main */
  main .product_list li {
    display: flex;
    gap: 1.2rem;
  }

  main .product_list li.product_item span:first-child,
  main .product_list li.lookbook_item span:first-child {
    width: 72px;
  }

  main .product_list li.product_item span:nth-child(2),
  main .product_list li.lookbook_item span:nth-child(2) {
    flex: 1; /* 나머지 너비를 차지하도록 설정 */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  main .product_list li.product_item span:nth-child(3) {
    /* width: 58px; */
    text-align: right;
  }

  main .product_list li.product_item span:nth-child(4),
  main .product_list li.product_item span:nth-child(5),
  main .product_list li.product_item span:last-child {
    display: none;
  }

  /** Drawer */
  #drawer {
    width: 100vw;
    height: 100dvh;
    position: absolute;
  }

  #drawer .product-detail {
    -webkit-overflow-scrolling: touch;
  }

  #drawer .product-detail > div:nth-child(2) {
    width: 86%;
  }

  #drawer .product_info {
    padding-bottom: 0.6rem;
  }

  #drawer .product-detail > div:nth-child(2) .bullets li {
    width: 0.4rem;
    height: 0.4rem;
  }

  #drawer .lookbook-detail > div:nth-child(2),
  #drawer .lookbook-detail div:nth-child(2).isWideImage {
    width: 86%;
  }

  .menu-items {
    gap: 1rem;
  }

  /** Hover Style Default */
  .products li .hover-text {
    padding: 2rem;
  }

  main .product_list li::after {
    display: none;
  }

  /** Footer */
  footer > div:first-child svg {
    height: 16px;
    /*width: fit-content;*/
  }

  footer > div:first-child svg:first-child {
    width: 150px;
  }

  footer > div:first-child svg:last-child {
    width: 100px;
  }


  footer .nav-footer {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
}
