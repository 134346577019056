@font-face {
  font-family: 'Unica 77 MonoLL';
  font-style: normal;
  font-weight: 400;
  src:
    url('/public/static/fonts/Unica77MonoLLSub-Regular.woff') format('woff'),
    url('/public/static/fonts/Unica77MonoLLSub-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Unica 77 MonoLL';
  font-style: normal;
  font-weight: 400;
  src:
    url('/public/static/fonts/Unica77MonoLLWeb-Regular.woff') format('woff'),
    url('/public/static/fonts/Unica77MonoLLWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Unica 77 MonoLowLL';
  font-style: normal;
  font-weight: 400;
  src:
    url('/public/static/fonts/Unica77MonoLowLLSub-Regular.woff') format('woff'),
    url('/public/static/fonts/Unica77MonoLowLLSub-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Unica 77 MonoLowLL';
  font-style: normal;
  font-weight: 400;
  src:
    url('/public/static/fonts/Unica77MonoLowLLWeb-Regular.woff') format('woff'),
    url('/public/static/fonts/Unica77MonoLowLLWeb-Regular.woff2') format('woff2');
}