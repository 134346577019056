* {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  width: fit-content;
  background: none;
  border: 0;
  text-transform: inherit;
  cursor: pointer;
}

input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
  /* display: none; */
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

h1,
h2,
p {
  margin: 0;
  padding: 0;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  border-bottom: none;
  border-top: 1px solid #000;
  margin: 0;
}

pre {
  white-space: pre-wrap;
}

section {
  padding: 1rem 0;
}

fieldset {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  padding: 1rem;
}

form {
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
